import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaCaretDown, FaSignOutAlt, FaHome } from 'react-icons/fa';

const Sidebar = ({ isOpen, toggleSidebar, user, setUser }) => {
    const [isPrimeDropdownOpen, setPrimeDropdownOpen] = useState(false);
    const [isSimpleDropdownOpen, setSimpleDropdownOpen] = useState(false);
    const [isGsmDropdownOpen, setGsmDropdownOpen] = useState(false);
    const [isAdresDropdownOpen, setAdresDropdownOpen] = useState(false);
    const [isKisiDropdownOpen, setKisiDropdownOpen] = useState(false);
    const [isSaglikDropdownOpen, setisSaglikDropdownOpen] = useState(false);

    const togglePrimeDropdown = () => {
        setPrimeDropdownOpen(!isPrimeDropdownOpen);
    };

    const toggleSaglikDropdown = () => {
        setisSaglikDropdownOpen(!isSaglikDropdownOpen);
    };

    const toggleSimpleDropdown = () => {
        setSimpleDropdownOpen(!isSimpleDropdownOpen);
    };

    const toggleGsmDropdown = () => {
        setGsmDropdownOpen(!isGsmDropdownOpen);
    };

    const toggleAdresDrowdown = () => {
        setAdresDropdownOpen(!isAdresDropdownOpen);
    };

    const toggleKisiDrowdown = () => {
        setKisiDropdownOpen(!isKisiDropdownOpen);
    };

    const logout = async () => {
        localStorage.removeItem("token");
        setUser(null);
        window.location = "/login";
    };

    const styles = {
        sidebar: {
            width: isOpen ? '250px' : "0px",
            height: '100vh',
            backgroundColor: '#151B2B',
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: isOpen ? '20px' : '10px 5px',
            boxSizing: 'border-box',
            position: 'fixed',
            top: 0,
            left: 0,
            overflowX: 'hidden',
            transition: 'width 0.3s, padding 0.3s',
            zIndex: 1000,
            boxShadow: '2px 0 5px rgba(0, 0, 0, 0.2)',
        },
        menuButton: {
            position: 'fixed',
            top: '20px',
            left: isOpen ? '250px' : '20px',
            fontSize: '24px',
            color: '#fff',
            cursor: 'pointer',
            transition: 'left 0.3s',
            zIndex: 1100,
        },
        userProfile: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            transition: 'opacity 0.3s',
            opacity: isOpen ? 1 : 0,
        },
        userProfileImg: {
            borderRadius: '50%',
            marginRight: '10px',
            width: "30px"
        },
        userName: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        userType: {
            fontSize: '14px',
            color: '#aaa',
        },
        link: {
            color: '#fff',
            textDecoration: 'none',
            margin: '10px 0',
            fontSize: '18px',
            display: 'flex',
            alignItems: 'center',
            padding: '10px 15px',
            transition: 'background-color 0.3s, color 0.3s',
            borderRadius: '5px',
        },
        linkHover: {
            backgroundColor: '#00ccff',
            color: '#000',
        },
        linkIcon: {
            marginRight: isOpen ? '10px' : '0',
            fontSize: '20px',
        },
        linkText: {
            display: isOpen ? 'inline' : 'none',
            transition: 'display 0.3s',
        },
        dropdownTitle: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 15px',
            transition: 'background-color 0.3s, color 0.3s',
            borderRadius: '5px',
            fontSize: '18px',
            fontWeight: 'bold',
            margin: '10px 0',
        },
        dropdownTitleHover: {
            backgroundColor: '#00ccff',
            color: '#000',
        },
        dropdownIcon: {
            transition: 'transform 0.3s',
        },
        dropdownOpenIcon: {
            transform: 'rotate(180deg)',
        },
        dropdownContent: {
            display: 'none',
            paddingLeft: '20px',
        },
        dropdownOpen: {
            display: 'block',
        },
        dropdownItem: {
            color: '#888',
            textDecoration: 'none',
            margin: '5px 0',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            padding: '8px 15px',
            transition: 'background-color 0.3s, color 0.3s',
            borderRadius: '5px',
        },
        dropdownItemHover: {
            backgroundColor: '#00ccff',
            color: '#000',
        },
        premiumLabel: {
            fontSize: '12px',
            color: '#FFD700', // Altın rengi
            marginLeft: '5px',
            fontWeight: 'bold',
            fontStyle: 'italic',
        },
        footer: {
            fontSize: '14px',
            color: '#aaa',
            textAlign: 'center',
            padding: '10px 0',
        }
    };

    return (
        <div>
            <FaBars style={styles.menuButton} onClick={toggleSidebar} />
            <div style={styles.sidebar}>
                <div>
                    <div style={styles.userProfile}>
                        <img src="./jirgologo.png" alt="User" style={styles.userProfileImg} />
                        <div>
                            <div style={styles.userName}>{user.name}</div>
                            <div style={styles.userType}>{user.type}</div>
                        </div>
                    </div>
                    {user.type === "Admin" ? (
                        <Link to="/dashboard" style={styles.link}>
                            <FaHome style={styles.linkIcon} />
                            <span style={styles.linkText}>Admin Panel</span>
                        </Link>
                    ) : null}
                    <Link to="/" style={styles.link}>
                        <FaHome style={styles.linkIcon} />
                        <span style={styles.linkText}>Ana Sayfa</span>
                    </Link>
                    <div>
                        <div style={styles.dropdownTitle} onClick={toggleSimpleDropdown} >
                            <span style={styles.linkText}>Kişi Çözümleri</span>
                            <FaCaretDown style={{ ...styles.dropdownIcon, ...(isSimpleDropdownOpen ? styles.dropdownOpenIcon : {}) }} />
                        </div>
                        <div style={{ ...styles.dropdownContent, ...(isSimpleDropdownOpen ? styles.dropdownOpen : {}) }}>
                            <Link to="/adsoyadpro" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Ad Soyad Pro</span>
                            </Link>
                            <Link to="/tcsorgu" style={styles.dropdownItem} >
                                <span style={styles.linkText}>TC Sorgu</span>
                            </Link>
                            <Link to="/sulale" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Sülale Sorgu</span>
                            </Link>
                            <Link to="/hikaye" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Hayat Hikayesi</span>
                                <span style={styles.premiumLabel}>(Premium)</span>
                            </Link>
                            <Link to="/iban" style={styles.dropdownItem} >
                                <span style={styles.linkText}>IBAN Sorgu</span>
                            </Link>
                            <Link to="/ipsorgu" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Konumlu IP Sorgu</span>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <div style={styles.dropdownTitle} onClick={toggleGsmDropdown}>
                            <span style={styles.linkText}>GSM Çözümleri</span>
                            <FaCaretDown style={{ ...styles.dropdownIcon, ...(isGsmDropdownOpen ? styles.dropdownOpenIcon : {}) }} />
                        </div>
                        <div style={{ ...styles.dropdownContent, ...(isGsmDropdownOpen ? styles.dropdownOpen : {}) }}>
                            <Link to="/gsmtc" style={styles.dropdownItem} >
                                <span style={styles.linkText}>GSM TC</span>
                            </Link>
                            <Link to="/tcgsm" style={styles.dropdownItem} >
                                <span style={styles.linkText}>TC GSM</span>
                            </Link>
                            <Link to="/ailegsm" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Aile GSM</span>
                                <span style={styles.premiumLabel}>(Premium)</span>
                            </Link>
                            <Link to="/sms" style={styles.dropdownItem} >
                                <span style={styles.linkText}>SMS Bomber</span>
                                <span style={styles.premiumLabel}>(Premium)</span>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <div style={styles.dropdownTitle} onClick={togglePrimeDropdown}>
                            <span style={styles.linkText}>Prime Çözümler</span>
                            <FaCaretDown style={{ ...styles.dropdownIcon, ...(isPrimeDropdownOpen ? styles.dropdownOpenIcon : {}) }} />
                        </div>
                        <div style={{ ...styles.dropdownContent, ...(isPrimeDropdownOpen ? styles.dropdownOpen : {}) }}>
                            <Link to="/eokul" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Okul No</span>
                            </Link>
                            <Link to="/universite" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Üniversite</span>
                                <span style={styles.premiumLabel}>(Premium)</span>
                            </Link>
                            <Link to="/parsel" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Tapu & Parsel</span>
                                <span style={styles.premiumLabel}>(Premium)</span>
                            </Link>
                            <Link to="/adaparsel" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Ada Parsel</span>
                                <span style={styles.premiumLabel}>(Premium)</span>
                            </Link>
                            <Link to="/isyeri" style={styles.dropdownItem} >
                                <span style={styles.linkText}>İşçi Sorgu</span>
                                <span style={styles.premiumLabel}>(Premium)</span>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <div style={styles.dropdownTitle} onClick={toggleAdresDrowdown}>
                            <span style={styles.linkText}>Adres Çözümleri</span>
                            <FaCaretDown style={{ ...styles.dropdownIcon, ...(isAdresDropdownOpen ? styles.dropdownOpenIcon : {}) }} />
                        </div>
                        <div style={{ ...styles.dropdownContent, ...(isAdresDropdownOpen ? styles.dropdownOpen : {}) }}>
                            <Link to="/adrespro" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Fotoğraflı Anlık Adres+</span>
                                <span style={styles.premiumLabel}>(Premium)</span>
                            </Link>
                            <Link to="/adres" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Fotoğraflı Adres 2023</span>
                            </Link>
                            <Link to="/esnaf" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Fotoğraflı İş Yeri Sorgu 2024</span>
                                <span style={styles.premiumLabel}>(Premium)</span>
                            </Link>
                            <Link to="/hane" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Hane</span>
                            </Link>
                        </div>
                    </div>
                    <div style={styles.dropdownTitle} onClick={toggleSaglikDropdown}>
                        <span style={styles.linkText}>Sağlık Çözümleri</span>
                        <FaCaretDown style={{ ...styles.dropdownIcon, ...(isAdresDropdownOpen ? styles.dropdownOpenIcon : {}) }} />
                    </div>
                    <div style={{ ...styles.dropdownContent, ...(isSaglikDropdownOpen ? styles.dropdownOpen : {}) }}>
                        <Link to="/ilac" style={styles.dropdownItem} >
                            <span style={styles.linkText}>İlaç</span>
                            <span style={styles.premiumLabel}>(Premium)</span>
                        </Link>
                        <Link to="/sigorta" style={styles.dropdownItem} >
                            <span style={styles.linkText}>Sigorta</span>
                            <span style={styles.premiumLabel}>(Premium)</span>
                        </Link>
                        <Link to="/rapor" style={styles.dropdownItem} >
                            <span style={styles.linkText}>Rapor</span>
                            <span style={styles.premiumLabel}>(Premium)</span>
                        </Link>
                        <Link to="/raporpro" style={styles.dropdownItem} >
                            <span style={styles.linkText}>Detaylı Rapor</span>
                            <span style={styles.premiumLabel}>(Premium)</span>
                        </Link>
                        <Link to="/hastane" style={styles.dropdownItem} >
                            <span style={styles.linkText}>Hastane Rapor</span>
                            <span style={styles.premiumLabel}>(Premium)</span>
                        </Link>
                    </div>
                    <div>
                        <div style={styles.dropdownTitle} onClick={toggleKisiDrowdown}>
                            <span style={styles.linkText}>Detay Çözümleri</span>
                            <FaCaretDown style={{ ...styles.dropdownIcon, ...(isKisiDropdownOpen ? styles.dropdownOpenIcon : {}) }} />
                        </div>
                        <div style={{ ...styles.dropdownContent, ...(isKisiDropdownOpen ? styles.dropdownOpen : {}) }}>
                            <Link to="/adsoyadproplus" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Ad Soyad Pro Plus</span>
                            </Link>
                            <Link to="/tcpro" style={styles.dropdownItem} >
                                <span style={styles.linkText}>TC Pro</span>
                            </Link>
                            <Link to="/serino" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Seri No Plus+</span>
                                <span style={styles.premiumLabel}>(Premium)</span>
                            </Link>
                            <Link to="/kimlik" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Kimlik</span>
                                <span style={styles.premiumLabel}>(Premium)</span>
                            </Link>
                            <Link to="/guncelaile" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Güncel Aile</span>
                                <span style={styles.premiumLabel}>(Premium)</span>
                            </Link>
                            <Link to="/randevu" style={styles.dropdownItem} >
                                <span style={styles.linkText}>Randevu</span>
                                <span style={styles.premiumLabel}>(Premium)</span>
                            </Link>
                        </div>
                    </div>
                    <Link onClick={logout} style={styles.link} >
                        <FaSignOutAlt style={styles.linkIcon} />
                        <span style={styles.linkText}>Çıkış Yap</span>
                    </Link>
                </div>
                <div style={styles.footer}>
                    Made By Jirgo, DeparOsman <span role="img" aria-label="heart">❤️</span>
                    <br />
                    2023 - 2024
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
