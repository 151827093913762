import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Kimlik = ({ user }) => {
    const [tc, setTC] = useState("");
    const [kisi, setKisi] = useState(null);
    const [fotograf, setFotograf] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleKimlik = async (e) => {
        e.preventDefault();
        if (user.type !== "Premium" && user.type !== "Admin") {
            return toast.error("Bu Sorguyu Kullanabilmen İçin Premium Satın Almalısın", { autoClose: 3000 });
        }
        setIsSubmitting(true);
        toast.info("Loading...", { autoClose: false });
        try {
            const response = await axios.post(`https://jirgocheck.online/jirgo/api/kimlik`, { tc }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                }
            });
            console.log(response.data);
            const data = response.data.data;
            setKisi(data.kisi);
            setFotograf(data.data);
            toast.dismiss();
            if (data.kisi) {
                toast.success(`Veri başarıyla bulundu!`, { autoClose: 3000 });
            } else {
                toast.warn("Hane bilgisi bulunamadı.", { autoClose: 3000 });
            }
        } catch (error) {
            toast.dismiss();
            toast.error("Veri bulunamadı.", { autoClose: 3000 });
            setKisi(null);
            setFotograf("");
        } finally {
            setIsSubmitting(false);
        }
    };

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: '#1E1E1E',
            color: '#61dafb',
            padding: '20px',
            boxSizing: 'border-box',
            margin: '0 auto'
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '1000px',
            backgroundColor: '#252526',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        },
        inputGroup: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '10px',
            flexWrap: 'wrap',
        },
        textbox: {
            width: '100%',
            padding: '15px',
            fontSize: '16px',
            backgroundColor: '#333',
            color: '#61dafb',
            border: '1px solid #61dafb',
            borderRadius: '5px',
            marginBottom: '10px'
        },
        submitButton: {
            padding: '15px 30px',
            fontSize: '18px',
            backgroundColor: '#61dafb',
            color: '#1E1E1E',
            border: 'none',
            borderRadius: '5px',
            marginTop: '20px',
            transition: 'background-color 0.3s ease',
            opacity: isSubmitting ? 0.6 : 1,
            cursor: isSubmitting ? 'not-allowed' : 'pointer'
        },
        submitButtonHover: {
            backgroundColor: isSubmitting ? '#61dafb' : '#21a1f1'
        },
        tableWrapper: {
            width: '100%',
            maxWidth: '1300px',
            overflowX: 'auto',
            margin: '20px auto'
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            border: '1px solid #61dafb',
        },
        tableHeader: {
            backgroundColor: '#333',
            color: '#61dafb',
            padding: '10px',
            textAlign: 'center',
            borderBottom: '2px solid #61dafb'
        },
        tableCell: {
            backgroundColor: '#444',
            color: '#61dafb',
            padding: '10px',
            borderBottom: '1px solid #61dafb',
            textAlign: 'center',
            border: '1px solid #61dafb'
        },
        pagination: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            width: '100%',
            maxWidth: '300px',
            color: '#61dafb'
        },
        paginationButton: {
            padding: '10px 15px',
            backgroundColor: '#61dafb',
            color: '#1E1E1E',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            margin: '0 5px'
        },
        paginationButtonHover: {
            backgroundColor: '#21a1f1'
        },
        paginationDisabled: {
            backgroundColor: '#444',
            cursor: 'not-allowed'
        },
        '@media (max-width: 600px)': {
            inputGroup: {
                flexDirection: 'column'
            },
            textbox: {
                width: '100%',
                marginBottom: '10px'
            },
            tableWrapper: {
                padding: '0 10px'
            },
            table: {
                width: 'auto'
            }
        }
    };

    return (
        <div style={styles.container}>
            <ToastContainer position="top-right" />
            <h2 style={{ color: '#61dafb' }}>Kimlik</h2>
            <form style={styles.form} onSubmit={handleKimlik}>
                <div style={styles.inputGroup} className="input-group">
                    <input
                        maxLength={11}
                        type="text"
                        onChange={e => setTC(e.target.value)}
                        placeholder="TC"
                        required
                        style={styles.textbox}
                        className="textbox"
                    />
                </div>
                <button
                    type="submit"
                    style={styles.submitButton}
                    disabled={isSubmitting}
                    onMouseEnter={(e) => !isSubmitting && (e.target.style.backgroundColor = styles.submitButtonHover.backgroundColor)}
                    onMouseLeave={(e) => !isSubmitting && (e.target.style.backgroundColor = styles.submitButton.backgroundColor)}
                >
                    Sorgula
                </button>
            </form>
            {kisi && (
                <div style={styles.contentWrapper}>
                    <h3 style={{ color: '#61dafb' }}>Kişi Bilgileri</h3>
                    <div style={styles.tableWrapper}>
                        <table style={styles.table}>
                            <thead>
                                <tr>
                                    <th style={styles.tableHeader}>TC</th>
                                    <th style={styles.tableHeader}>Ad</th>
                                    <th style={styles.tableHeader}>Soyad</th>
                                    <th style={styles.tableHeader}>Cinsiyet</th>
                                    <th style={styles.tableHeader}>Doğum Tarihi</th>
                                    <th style={styles.tableHeader}>Doğum Yeri</th>
                                    <th style={styles.tableHeader}>Baba Adi</th>
                                    <th style={styles.tableHeader}>Anne Adı</th>
                                    <th style={styles.tableHeader}>Medeni Hal</th>
                                    <th style={styles.tableHeader}>Ölüm Tarihi</th>
                                    <th style={styles.tableHeader}>Seri No</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={styles.tableCell}>{kisi?.TC || "N/A"}</td>
                                    <td style={styles.tableCell}>{kisi?.ADI || "N/A"}</td>
                                    <td style={styles.tableCell}>{kisi?.SOYADI || "N/A"}</td>
                                    <td style={styles.tableCell}>{kisi?.CINSIYET || "N/A"}</td>
                                    <td style={styles.tableCell}>{kisi?.DOGUMTARIHI || "N/A"}</td>
                                    <td style={styles.tableCell}>{kisi?.DOGUMYERI || "N/A"}</td>
                                    <td style={styles.tableCell}>{kisi?.BABAADI || "N/A"}</td>
                                    <td style={styles.tableCell}>{kisi?.ANNEADI || "N/A"}</td>
                                    <td style={styles.tableCell}>{kisi?.MEDENIHAL || "N/A"}</td>
                                    <td style={styles.tableCell}>{kisi?.OLUMTARIHI || "N/A"}</td>
                                    <td style={styles.tableCell}>{kisi?.SERINO || "N/A"}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {fotograf && (
                        <div style={styles.photoWrapper}>
                            <h3 style={{ color: '#61dafb' }}>Kimlik Fotoğrafı</h3>
                            <img src={`data:image/png;base64,${fotograf}`} alt="Kimlik" style={styles.photo} width="400px" />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Kimlik;
