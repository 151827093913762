import React, { useEffect } from 'react';

const Home = ({ user }) => {
    const styles = {
        container: {
            color: 'white',
            backgroundColor: '#1E1E1E',
            height: '100vh',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        infoBox: {
            marginTop: '20px',
            border: '1px solid #00BFFF',
            padding: '20px',
            borderRadius: '5px',
            width: '100%',
            maxWidth: '800px',
            textAlign: 'center'
        },
        title: {
            color: 'white',
            fontSize: '2em',
            animation: 'fade 5s infinite'
        },
        welcome: {
            color: 'white',
            marginTop: '10px'
        },
        userDetails: {
            textAlign: 'center',
            marginTop: '20px'
        },
        announcementBox: {
            marginTop: '20px',
            border: '1px solid #00BFFF',
            padding: '20px',
            borderRadius: '5px',
            width: '100%',
            maxWidth: '800px',
            textAlign: 'center'
        },
        announcementTitle: {
            color: 'white',
            fontSize: '1.5em'
        },
        announcementText: {
            color: 'white',
            margin: '10px 0'
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px'
        },
        linkButton: {
            margin: '0 10px',
            padding: '10px 20px',
            backgroundColor: '#00BFFF',
            color: 'black',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease'
        },
        footer: {
            marginTop: '20px',
            textAlign: 'center'
        },
        glowingText: {
            color: '#00BFFF',
            fontSize: '1.5em',
            animation: 'glow 2s infinite'
        },
        link: {
            textDecoration: 'none',
            color: 'black'
        },
        '@keyframes fade': {
            '0%, 100%': { opacity: 1 },
            '50%': { opacity: 0.5 }
        },
        '@keyframes glow': {
            '0%, 100%': {
                textShadow: '0 0 5px #00BFFF, 0 0 10px #00BFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF'
            },
            '50%': {
                textShadow: '0 0 10px #00BFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF, 0 0 40px #00BFFF'
            }
        }
    };

    useEffect(() => {
        alert("Telegrama ve Discorda gelmek zorunludur")
    }, [])

    return (
        <div style={styles.container}>
            <style>
                {`
                    @keyframes fade {
                        0%, 100% { opacity: 1; }
                        50% { opacity: 0.5; }
                    }
                    @keyframes glow {
                        0%, 100% {
                            text-shadow: 0 0 5px #00BFFF, 0 0 10px #00BFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF;
                        }
                        50% {
                            text-shadow: 0 0 10px #00BFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF, 0 0 40px #00BFFF;
                        }
                    }
                `}
            </style>
            <div style={styles.infoBox}>
                <h1 style={styles.glowingText}>Jirgo Checker</h1>
                <p style={styles.welcome}>Hoşgeldin, {user?.name}</p>
                <div style={styles.userDetails}>
                    <p><strong>Kullanıcı Adı:</strong> {user?.name}</p>
                    <p><strong>Hesap Tipi:</strong> {user?.type}</p>
                    <p><strong>Bitiş Tarihi:</strong> {user?.tarih}</p>
                    <p><strong>Email:</strong> {user?.email}</p>
                </div>
            </div>
            <div style={styles.announcementBox}>
                <h2 style={styles.announcementTitle}>Duyurular</h2>
                <p style={styles.announcementText}>Sorularınız ve görüşleriniz için telegramdan ulaşın</p>
                <br />
                <p style={styles.announcementText}>PANELİMİZ ÜCRETLİ OLMUŞTUR SATIN ALIMLAR İÇİN SADECE @DeparOsman BAŞKA KİMSE TARAFINDAN ÜCRET ALINAMAZ
                    <br/>
                    İlaç ve Rapor sorgumuz ilk denemede yanıt getirmeyebilir 3 kere deneyin eğer 3 kere sonuç vermezse kişinin ilaç ve ya rapor kaydı yoktur.

                    Pasif Sorgular: Üniversite , Sigorta
                </p>
            </div>
            <div style={styles.buttonContainer}>
                <button style={styles.linkButton}>
                    <a href="https://discord.com/invite/V4YTn2KKhE" target="_blank" rel="noopener noreferrer" style={styles.link}>Discord</a>
                </button>
                <button style={styles.linkButton}>
                    <a href="https://t.me/jirgocheck" target="_blank" rel="noopener noreferrer" style={styles.link}>Telegram</a>
                </button>
            </div>
            <div style={styles.footer}>
                <p style={styles.title}>Jirgo Checker</p>
            </div>
        </div>
    );
}

export default Home;
