import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import Home from "./home";
import Adsoyadpro from "./sorgular/adsoyadpro";
import TCPro from "./sorgular/tcpro";
import GsmTc from "./sorgular/gsmtc";
import Tcgsm from "./sorgular/tcgsm";
import TcSorgu from "./sorgular/tcsorgu";
import Iban from "./sorgular/iban";
import IpSorgu from "./sorgular/ipsorgu";
import { useState } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Sidebar from "./sidebar";
import Esnaf from "./sorgular/esnaf";
import Dashboard from "./dashboard";
import Register from "./register";
import NotFound from "./notFound.js"; // Import the NotFound component
import Adres from "./sorgular/adres.js";
import AdresPro from "./sorgular/adrespro.js";
import Sulale from "./sorgular/sulale.js";
import AdSoyadProPlus from "./sorgular/adsoyadproplus.js";
import Sms from "./sorgular/sms.js"
import Seri from "./sorgular/serino.js"
import Hikaye from "./sorgular/hikaye.js"
import AileGsm from "./sorgular/ailegsm.js";
import TcParsel from "./sorgular/tcparsel.js";
import AdaParsel from "./sorgular/adaparsel.js";
import Isyeri from "./sorgular/isyeri.js";
import OkulNo from "./sorgular/okulno.js";
import Randevu from "./sorgular/randevu.js";
import Hane from "./sorgular/hane.js";
import Sigorta from "./sorgular/sigorta.js";
import Ilac from "./sorgular/ilac.js";
import Uni from "./sorgular/uni.js";
import Kimlik from "./sorgular/kimlik.js";
import Rapor from "./sorgular/rapor.js";
import Hastane from "./sorgular/hastanerapor.js"
import GuncelAile from "./sorgular/guncelaile.js";
import RaporProPlus from "./sorgular/raporproplus.js";

function App() {
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route element={<ProtectedRoute setUser={setUser} user={user} />}>
          <Route
            path="*"
            element={
              <>
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} user={user} setUser={setUser} />
                <div style={{ width: '100%', transition: 'margin-left 0.3s', position: 'relative', zIndex: 1 }}>
                  <Routes>
                    <Route path="/" element={<Home user={user} />} />
                    <Route path="/dashboard" element={<Dashboard user={user} />} />
                    <Route path="/adsoyadpro" element={<Adsoyadpro />} />
                    <Route path="/adsoyadproplus" element={<AdSoyadProPlus />} />
                    <Route path="/gsmtc" element={<GsmTc />} />
                    <Route path="/tcgsm" element={<Tcgsm />} />
                    <Route path="/tcsorgu" element={<TcSorgu />} />
                    <Route path="/iban" element={<Iban />} />
                    <Route path="/ipsorgu" element={<IpSorgu />} />
                    <Route path="/tcpro" element={<TCPro />} />
                    <Route path="/esnaf" element={<Esnaf user={user} />} />
                    <Route path="/adres" element={<Adres />} />
                    <Route path="/adrespro" element={<AdresPro user={user} />} />
                    <Route path="/sulale" element={<Sulale />} />
                    <Route path="/sms" element={<Sms user={user} />} />
                    <Route path="/serino" element={<Seri user={user} />} />
                    <Route path="/hikaye" element={<Hikaye user={user} />} />
                    <Route path="/ailegsm" element={<AileGsm user={user} />} />
                    <Route path="/parsel" element={<TcParsel user={user} />} />
                    <Route path="/adaparsel" element={<AdaParsel user={user} />} />
                    <Route path="/isyeri" element={<Isyeri user={user} />} />
                    <Route path="/eokul" element={<OkulNo />} />
                    <Route path="/randevu" element={<Randevu user={user} />} />
                    <Route path="/hane" element={<Hane user={user} />} />
                    <Route path="/sigorta" element={<Sigorta user={user} />} />
                    <Route path="/ilac" element={<Ilac user={user} />} />
                    <Route path="/universite" element={<Uni user={user} />} />
                    <Route path="/kimlik" element={<Kimlik user={user} />} />
                    <Route path="/rapor" element={<Rapor user={user} />} />
                    <Route path="/hastane" element={<Hastane user={user} />} />
                    <Route path="/guncelaile" element={<GuncelAile user={user} />} />
                    <Route path="/raporpro" element={<RaporProPlus user={user} />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </div>
              </>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
