import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RaporProPlus = ({ user }) => {
    const [tc, setTC] = useState("");
    const [raporDetay, setRaporDetay] = useState(null);
    const [raporlar, setRaporlar] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dataPerPage = 5; // Sayfa başına gösterilecek rapor sayısı

    const handleRaporProPlus = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        toast.info("Loading...", { autoClose: false });
        try {
            const response = await axios.post(`https://jirgocheck.online/jirgo/api/raporproplus`, { tc }, {
                headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                }
            });
            console.log(response.data);
            const data = response.data.data;
            setRaporDetay(data.IlkDetay);
            setRaporlar(data.Raporlar.sonuc);
            setCurrentPage(0);
            toast.dismiss();
            if (data.Raporlar.sonuc.length > 0) {
                toast.success(`Veri başarıyla bulundu!`, { autoClose: 3000 });
            } else {
                toast.warn("Rapor bilgisi bulunamadı.", { autoClose: 3000 });
            }
        } catch (error) {
            toast.dismiss();
            toast.error("Veri bulunamadı.", { autoClose: 3000 });
            setRaporDetay(null);
            setRaporlar([]);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handlePageClick = (direction) => {
        if (direction === "next" && currentPage < Math.ceil(raporlar.length / dataPerPage) - 1) {
            setCurrentPage(currentPage + 1);
        } else if (direction === "prev" && currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const offset = currentPage * dataPerPage;
    const currentData = raporlar.slice(offset, offset + dataPerPage);

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: '#1E1E1E',
            color: '#61dafb',
            padding: '20px',
            boxSizing: 'border-box',
            margin: '0 auto'
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '1000px',
            backgroundColor: '#252526',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        },
        inputGroup: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '10px',
            flexWrap: 'wrap',
        },
        textbox: {
            width: '100%',
            padding: '15px',
            fontSize: '16px',
            backgroundColor: '#333',
            color: '#61dafb',
            border: '1px solid #61dafb',
            borderRadius: '5px',
            marginBottom: '10px'
        },
        submitButton: {
            padding: '15px 30px',
            fontSize: '18px',
            backgroundColor: '#61dafb',
            color: '#1E1E1E',
            border: 'none',
            borderRadius: '5px',
            marginTop: '20px',
            transition: 'background-color 0.3s ease',
            opacity: isSubmitting ? 0.6 : 1,
            cursor: isSubmitting ? 'not-allowed' : 'pointer'
        },
        tableWrapper: {
            width: '100%',
            maxWidth: '1300px',
            overflowX: 'auto',
            margin: '20px auto'
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            border: '1px solid #61dafb',
        },
        tableHeader: {
            backgroundColor: '#333',
            color: '#61dafb',
            padding: '10px',
            textAlign: 'center',
            borderBottom: '2px solid #61dafb'
        },
        tableCell: {
            backgroundColor: '#444',
            color: '#61dafb',
            padding: '10px',
            borderBottom: '1px solid #61dafb',
            textAlign: 'center',
            border: '1px solid #61dafb'
        },
        pagination: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            width: '100%',
            maxWidth: '300px',
            color: '#61dafb'
        },
        paginationButton: {
            padding: '10px 15px',
            backgroundColor: '#61dafb',
            color: '#1E1E1E',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            margin: '0 5px'
        },
        paginationDisabled: {
            backgroundColor: '#444',
            cursor: 'not-allowed'
        }
    };

    return (
        <div style={styles.container}>
            <ToastContainer position="top-right" />
            <h2 style={{ color: '#61dafb' }}>Detaylı Hastane Rapor</h2>
            <form style={styles.form} onSubmit={handleRaporProPlus}>
                <div style={styles.inputGroup} className="input-group">
                    <input
                        maxLength={11}
                        type="text"
                        onChange={e => setTC(e.target.value)}
                        placeholder="TC"
                        required
                        style={styles.textbox}
                        className="textbox"
                    />
                </div>
                <button
                    type="submit"
                    style={styles.submitButton}
                    disabled={isSubmitting}
                >
                    Sorgula
                </button>
            </form>
            {raporDetay && (
                <div style={styles.tableWrapper}>
                    <h3 style={{ color: '#61dafb' }}>Hak Sahibi Bilgileri</h3>
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.tableHeader}>TC Kimlik No</th>
                                <th style={styles.tableHeader}>Cinsiyeti</th>
                                <th style={styles.tableHeader}>Adı Soyadı</th>
                                <th style={styles.tableHeader}>Doğum Tarihi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={styles.tableCell}>{raporDetay.detay.haksahibi_bilgi["TC Kimlik No"]}</td>
                                <td style={styles.tableCell}>{raporDetay.detay.haksahibi_bilgi["Cinsiyeti"]}</td>
                                <td style={styles.tableCell}>{raporDetay.detay.haksahibi_bilgi["Adı Soyadı"]}</td>
                                <td style={styles.tableCell}>{raporDetay.detay.haksahibi_bilgi["Doğum Tarihi"]}</td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 style={{ color: '#61dafb' }}>Rapor Bilgileri</h3>
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.tableHeader}>Rapor No</th>
                                <th style={styles.tableHeader}>Rapor Tarihi</th>
                                <th style={styles.tableHeader}>Protokol No</th>
                                <th style={styles.tableHeader}>Tesis Ünvanı</th>
                                <th style={styles.tableHeader}>Rapor Takip No</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={styles.tableCell}>{raporDetay.detay.rapor_bilgi["Rapor Numarası (*)"]}</td>
                                <td style={styles.tableCell}>{raporDetay.detay.rapor_bilgi["Rapor Tarihi (*)"]}</td>
                                <td style={styles.tableCell}>{raporDetay.detay.rapor_bilgi["Protokol No"]}</td>
                                <td style={styles.tableCell}>{raporDetay.detay.rapor_bilgi["Tesis Ünvanı"]}</td>
                                <td style={styles.tableCell}>{raporDetay.detay.rapor_bilgi["Rapor Takip No"]}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h3 style={{ color: '#61dafb' }}>Tanı Bilgileri</h3>
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.tableHeader}>Tanı</th>
                            </tr>
                        </thead>
                        <tbody>
                            {raporDetay.detay.tanı_bilgileri.map((tani, index) => (
                                <tr key={index}>
                                    <td style={styles.tableCell}>{tani.Tanı}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h3 style={{ color: '#61dafb' }}>Doktor Bilgileri</h3>
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.tableHeader}>Adı</th>
                                <th style={styles.tableHeader}>Soyadı</th>
                                <th style={styles.tableHeader}>Branş</th>
                                <th style={styles.tableHeader}>Diploma No</th>
                                <th style={styles.tableHeader}>Tescil No</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={styles.tableCell}>{raporDetay.detay.doktor_bilgileri.Adı}</td>
                                <td style={styles.tableCell}>{raporDetay.detay.doktor_bilgileri.Soyadı}</td>
                                <td style={styles.tableCell}>{raporDetay.detay.doktor_bilgileri.Branş}</td>
                                <td style={styles.tableCell}>{raporDetay.detay.doktor_bilgileri["Diploma No"]}</td>
                                <td style={styles.tableCell}>{raporDetay.detay.doktor_bilgileri["Tescil No"]}</td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 style={{ color: '#61dafb' }}>Rapor Etkin Maddeleri</h3>
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.tableHeader}>Kod</th>
                                <th style={styles.tableHeader}>Adı</th>
                                <th style={styles.tableHeader}>Form</th>
                                <th style={styles.tableHeader}>Tedavi Şeması</th>
                                <th style={styles.tableHeader}>Adet / Miktar</th>
                                <th style={styles.tableHeader}>İçerik Miktarı</th>
                            </tr>
                        </thead>
                        <tbody>
                            {raporDetay.detay.rapor_etkin_madde.map((madde, index) => (
                                <tr key={index}>
                                    <td style={styles.tableCell}>{madde.Kod}</td>
                                    <td style={styles.tableCell}>{madde.Adı}</td>
                                    <td style={styles.tableCell}>{madde.Form}</td>
                                    <td style={styles.tableCell}>{madde["Tedavi Şeması"]}</td>
                                    <td style={styles.tableCell}>{madde["Adet / Miktar"]}</td>
                                    <td style={styles.tableCell}>{madde["İçerik Miktarı"]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {raporlar.length > 0 && (
                <div style={styles.tableWrapper}>
                    <h3 style={{ color: '#61dafb' }}>Raporlar</h3>
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.tableHeader}>Rapor No</th>
                                <th style={styles.tableHeader}>Rapor Takip No</th>
                                <th style={styles.tableHeader}>Başlangıç Tarihi</th>
                                <th style={styles.tableHeader}>Bitiş Tarihi</th>
                                <th style={styles.tableHeader}>Tanı</th>
                                <th style={styles.tableHeader}>Kayıt Şekli</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.map((rapor, index) => (
                                <tr key={index}>
                                    <td style={styles.tableCell}>{rapor.rapor_no}</td>
                                    <td style={styles.tableCell}>{rapor.rapor_takip_no}</td>
                                    <td style={styles.tableCell}>{rapor.baslangic_tarihi}</td>
                                    <td style={styles.tableCell}>{rapor.bitis_tarihi}</td>
                                    <td style={styles.tableCell}>{rapor.tani}</td>
                                    <td style={styles.tableCell}>{rapor.kayit_sekli}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style={styles.pagination}>
                        <button
                            onClick={() => handlePageClick("prev")}
                            style={{
                                ...styles.paginationButton,
                                ...(currentPage === 0 ? styles.paginationDisabled : {})
                            }}
                            disabled={currentPage === 0}
                        >
                            ← Önceki
                        </button>
                        <button
                            onClick={() => handlePageClick("next")}
                            style={{
                                ...styles.paginationButton,
                                ...(currentPage === Math.ceil(raporlar.length / dataPerPage) - 1 ? styles.paginationDisabled : {})
                            }}
                            disabled={currentPage === Math.ceil(raporlar.length / dataPerPage) - 1}
                        >
                            Sonraki →
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RaporProPlus;
